import { putItem, postItem, deleteItem, patchItem } from '@/../../shared/api/SharedClient'
import { assortedProductSuccessAction, defaultErrorMessage } from '@/../../shared/constants/validationMessageHelper'
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'

export async function deleteStartmoment(courseId, startmomentId, store) {
    await deleteItem(`${process.env.VUE_APP_PROVIDER_V2_API_URL}${API_CALL_URL_PATHS.courses}/`, 
        `${courseId}/start-moments/${startmomentId}`, false)
    .then(() => {
        const toastNotification = {
            type: 'success',
            message: assortedProductSuccessAction('bijgewerkt')
        }

        store.dispatch('toastNotificationModule/add', toastNotification, {
            root: true
        })
    })
    .catch((error) => {
        const toastNotification = {
            type: 'error',
            message: defaultErrorMessage
        }

        store.dispatch('toastNotificationModule/add', toastNotification, {
            root: true
        })

        console.error('Something went wrong while deleting startmoment', error)
    })
}

export async function postStartmoment(courseId, item, store) {
    await postItem(`${process.env.VUE_APP_PROVIDER_V2_API_URL}${API_CALL_URL_PATHS.courses}/`, 
    `${courseId}/start-moments`, 
        item, 
        false)
    .then(() => {
        const toastNotification = {
            type: 'success',
            message: assortedProductSuccessAction('bijgewerkt')
        }
        store.dispatch('toastNotificationModule/add', toastNotification, {
            root: true
        })
    })
    .catch((error) => {
        const toastNotification = {
            type: 'error',
            message: defaultErrorMessage
        }

        store.dispatch('toastNotificationModule/add', toastNotification, {
            root: true
        })

        console.error('Something went wrong while posting a startmoment', error)
    })
}

export async function putStartmoment(courseId, startmoment, store) {
    await putItem(
        `${process.env.VUE_APP_PROVIDER_V2_API_URL}${API_CALL_URL_PATHS.courses}/`,
        courseId,
        `start-moments/${startmoment.id}`,
        startmoment,
        false
    )
    .then(async () => {
        const toastNotification = {
            type: 'success',
            message: assortedProductSuccessAction('bijgewerkt')
        }

        store.dispatch('toastNotificationModule/add', toastNotification, {
            root: true
        })
    })
    .catch((error) => {
        const toastNotification = {
            type: 'error',
            message: defaultErrorMessage
        }

        store.dispatch('toastNotificationModule/add', toastNotification, {
            root: true
        })
        
    })
}

export async function patchStartmoment(courseId, startmomentId, patchStartmomentObj, store) {
    await patchItem(
        `${process.env.VUE_APP_PROVIDER_V2_API_URL}${API_CALL_URL_PATHS.courses}/`,
        courseId,
        `start-moments/${startmomentId}`,
        patchStartmomentObj,
        false
    )
    .then(async () => {
        const toastNotification = {
            type: 'success',
            message: assortedProductSuccessAction('bijgewerkt')
        }

        store.dispatch('toastNotificationModule/add', toastNotification, {
            root: true
        })
    })
    .catch((error) => {
        const toastNotification = {
            type: 'error',
            message: defaultErrorMessage
        }

        store.dispatch('toastNotificationModule/add', toastNotification, {
            root: true
        })
        
    })
}

export function starmomentHasErrorsIncompany(copyCourse, course) {
    return copyCourse.learningMethod.planning.startMoments.some((startmoment, index) => {
        if (startmoment.cutOffDate && !startmoment.minParticipants) {
            course.learningMethod.planning.startMoments[index].hasErrors = true
            return true
        }

        return false
    })
}