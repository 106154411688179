import { postItem, deleteItem } from '@/../../shared/api/SharedClient'
import { assortedProductSuccessAction, defaultErrorMessage } from '@/../../shared/constants/validationMessageHelper'
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'

export async function deleteSegment(segmentId, store) {
    await deleteItem(
        process.env.VUE_APP_PROVIDER_API_URL,
        `${API_CALL_URL_PATHS.productSubSegments}/${segmentId}`,
        false
    )
    .then(() => {
        const toastNotification = {
            type: 'success',
            message: assortedProductSuccessAction('bijgewerkt')
        }

        store.dispatch('toastNotificationModule/add', toastNotification, {
            root: true
        })
    })
    .catch((error) => {
        const toastNotification = {
            type: 'error',
            message: defaultErrorMessage
        }

        store.dispatch('toastNotificationModule/add', toastNotification, {
            root: true
        })

        console.error('Something went wrong while deleting startmoment', error)
    })
}

export async function postSegment(segment, store) {
    await postItem(
        process.env.VUE_APP_PROVIDER_API_URL,
        API_CALL_URL_PATHS.productSubSegments,
        segment,
        false
    )
    .then(() => {
        const toastNotification = {
            type: 'success',
            message: assortedProductSuccessAction('bijgewerkt')
        }
        store.dispatch('toastNotificationModule/add', toastNotification, {
            root: true
        })
    })
    .catch((error) => {
        const toastNotification = {
            type: 'error',
            message: defaultErrorMessage
        }

        store.dispatch('toastNotificationModule/add', toastNotification, {
            root: true
        })

        console.error('Something went wrong while posting a startmoment', error)
    })
}