
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { getEnumList } from '@/../../shared/api/SharedClient'

export const getValuesFromObject = obj =>  obj !== null && obj.map(item => item.value)

export const emptyCourse = {
    externalId: '', 
    providerId: null,
    name: '', 
    commercialPartner: null,
    source: 'Manual',
    isProfessionalCourse: true,
    subsidies: null,
    croho: null,
    crebo: null,
    learningMethod: {
        type: null,
        format: null,
        contactSessions: null,
        costs: {
            details: [
                {
                    invoiceItems: [{
                        quantity: 1,
                        priceBeforeTax: null,
                        vatAmount: 0,
                        vatCountry: 'NL',
                        vatPercentage: null,
                        vatPercentageOptions: [],
                        vatExemptAmount: 0,
                        vatExemptAmountVAT: 0,
                        costType: null
                    }]
                }
               
            ]
        },
        duration: {
            unit: '',
            value: null
        },
        planning: {
            startMoments: []
        },
        studyLoad: {
            value: null,
            unit: null
        },
        language: '',
        languageCode: '',
        languageMaterial: '',
        languageMaterialCode: ''
    }, 
    content: [{
        intro: null,
        introHtml: null,
        description: null,
        descriptionHtml: null,
        blocks: [] 
    }],
    productType: 'Regular',
    startDate: '',
    endDate: '',
    educationLevelCode: null,
    segments: [{
        value: null,
        displayText: null,
        subSegments: [],
        filteredSubSegmentOptions: []
    }],
    diplomaType: null,
    accreditation: {
        source: null,
        accreditation: null,
        credit: null,
        points: null
    },
    credit: {
        type: null,
        typeDisplayValue: null,
        value: null
    },
    requirements: {
        description: [],
        type: null,
        typeDisplayValue: null
    },
    providerLmsLink: null,
    searchword: [],
    targetSector: null,
    targetSectorRijksoverheid: null,
    competences: null,
    competencesRijksoverheid: null 
}

/* eslint-disable guard-for-in */
export const emptyStartMomentItemSchema = {
    externalId: '',
    type: 'Day',
    city: '',
    streetName: '',
    houseNumber: '',
    postcode: '',
    dayOfWeek: null,
    cutOffDate: null,
    invoiceItems: [],
    startDate: null,
    startGuaranteed: null,
    minParticipants: null, // this must be null to be accepted by our server
    maxParticipants: null, // this must be null to be accepted by our server
    modules: [{
        name: 'Programma',
        city: '',
        dayOfWeek: null,
        events: [{
            eventDate: '',
            startTime: '',
            endTime: '',
            city: '',
            dayOfWeek: null,
            building: '',
            room: '',
            isActive: true
        }
        ],
        isActive: true
    }],
    isVisible: true,
    isActive: true, // to bind active attribute recess accordion to open newly added startmoment by default,
    hasErrors: false
}

export const emptyModuleItemSchema = {
    name: 'Programma',
    city: '',
    dayOfWeek: null,
    events: [
        {
            eventDate: '',
            startTime: '',
            endTime: '',
            city: '',
            dayOfWeek: null,
            building: '',
            room: '',
            isActive: true
        }
    ],
    isActive: true // to bind active attribute recess accordion to open newly added module by default
}

export const emptyEventItemSchema = {
    eventDate: '',
    startTime: '',
    endTime: '',
    city: '',
    dayOfWeek: null,
    building: '',
    room: '',
    isActive: true // to bind active attribute recess accordion to open newly added module by default
}

export const diplomaEmptySchema = {
    source: null,
    diplomaType: null,
    accreditation: {
        source: null,
        accreditation: null,
        credit: null,
        points: null
    },
    credit: {
        type: null,
        typeDisplayValue: null,
        value: null
    }
}

export const emptyInvoiceItem = {
    quantity: 1,
    priceBeforeTax: null,
    vatAmount: null,
    vatCountry: 'NL',
    vatPercentage: null,
    vatPercentageOptions: [],
    vatExemptAmount: 0,
    vatExemptAmountVAT: 0,
    costType: null,
    isOptional: false,
    displayVatPercentageOptions: false
}

export const emptyInvoiceSchema = {
    learningMethod: {
        costs: {
            details: [{
                invoiceItems: [emptyInvoiceItem]
            }]
        }
    }
} 

export const setGeneralInfoData = course => {
    return {
        id: course.id,
        name: course.name,
        externalId: course.externalId,
        isProfessionalCourse: course.isProfessionalCourse,
        commercialPartner: course.commercialPartner,
        productType: course.productType,
        shortName: course.shortName,
        source: course.source,
        edudexProgramUrl: course.edudexProgramUrl,
        startDate: course.startDate,
        endDate: course.endDate,
        learningMethod: course.learningMethod,
        selectedAccounts: course.selectedAccounts
    }
}

export const setContentData = course => {
    const expectedContent = [{
        intro: null,
        introHtml: null,
        description: null,
        descriptionHtml: null,
        blocks: []
    }]

    return course ? ({
        source: course.source,
        content: !!course.content.length ? course.content : expectedContent
    }) : ({ 
        content: expectedContent,  
        source: 'Manual'
    })
}


export const defaultContentSchema = { 
    intro: null,
    introHtml: null,
    description: null,
    descriptionHtml: null,
    blocks: [
        {
            heading: 'Leerdoelen',
            type: 'Objectives',
            displayValue: 'Leerdoelen',
            body: '',
            bodyHtml: '',
            selectedBody: 'text'
        }
    ]
}
 
 

export const setPlanningData = course => {
    return course ? ({
        source: course.source,
        productType: course.productType, 
        learningMethod: course.learningMethod
    }) : ({
        source: null,
        productType: null, 
        learningMethod: {
            contactSessions: null,
            costs: {
                details: [{}]
            },
            duration: {
                unit: null,
                unitDisplayValue: null,
                value: null
            },
            format: null,
            formatDisplayValue: null,
            language: null,
            languageCode: null,
            languageMaterial: null,
            languageMaterialCode: null,
            planning: {
                startMoments: []
            },
            studyLoad: {
                unit: null,
                unitDisplayValue: null,
                value: null
            },
            type: null,
            typeDisplayValue: null
        }
    })
}

export const setCategoriesData = course => {
    return {
        id: course.id,
        source: course.source,
        educationLevelCode: course.educationLevelCode, 
        segments: course.segments
    }
}

export const setDiplomaData = course => {
    return course ? ({
        source: course.source,
        diplomaType: course.diplomaType === null ?  diplomaEmptySchema.diplomaType : course.diplomaType,
        accreditation: course.accreditation === null ? diplomaEmptySchema.accreditation : course.accreditation,
        credit: course.credit === null ? diplomaEmptySchema.credit : course.credit 
    }) : ({
        ...diplomaEmptySchema
    })
}

export const setInvoiceItems = course => { 
    let startMomentItems = []
    if (course.learningMethod.planning !== null) {
        startMomentItems = course.learningMethod.planning.startMoments 
    }
    const invoiceItems = course.learningMethod.costs.details[0].invoiceItems
  
    if(startMomentItems.length > 0) {
        startMomentItems.forEach(startMoment => {
            if(startMoment.invoiceItems.length > 0) {
                startMoment.invoiceItems.forEach(invoiceItem => {  
                    invoiceItem.vatPercentageOptions = [] 
                }); 
            }
        });
    }  

    if(invoiceItems.length > 0) {
        invoiceItems.forEach(invoiceItem => {  
            invoiceItem.vatPercentageOptions = [] 
        });
    } 

    return course ? ({ 
        learningMethod: course.learningMethod,
        croho: course.croho,
        crebo: course.crebo
    }) : ({ ...emptyInvoiceSchema })
}  

export const setInvoiceData = course => {
    return course ? ({  
        subsidies: course.subsidies,
        learningMethod: course.learningMethod
    }) : ({ 
        subsidies: null,
        learningMethod: null
    })
}


export function updateMultiSelectList(property, isInitialized) {
    let mappedProperty
    if (Array.isArray(property) && property.length > 0) {
        mappedProperty = property.map(item => {
            return !isInitialized
                ? {
                    value: item
                }
                : item.value
        })
    }
    return mappedProperty
}

export function updateSubsidiestListBeforeSave(property, subsidiesOptions) {
    let mappedProperty
    if (Array.isArray(property) && property.length > 0) {
        mappedProperty = subsidiesOptions.filter(item => property.includes(item.value)).map((item) => ({value: item.value, displayText: item.displayText}))
    }
    return mappedProperty
}

export function updateEnumsBeforeSave(course, subsidiesOptions) {
    course.competences = updateMultiSelectList(course.competences)

    course.competencesRijksoverheid = updateMultiSelectList(course.competencesRijksoverheid)

    course.targetSector = updateMultiSelectList(course.targetSector)

    course.targetSectorRijksoverheid = updateMultiSelectList(course.targetSectorRijksoverheid)
    course.subsidies = updateSubsidiestListBeforeSave(course.subsidies, subsidiesOptions)

    updateEnums(course)

    return course
}
 
 function updateEnums(course) {
     if(!course)
     {
         return
     }
     if(course.diplomaType === '')
     {
         course.diplomaType = null
     }
     if(course.credit && course.credit.type === '')
     {
         course.credit.type = null
     }
     if(course.accreditation && course.accreditation.type === '')
     {
         course.accreditation.type = null
     }
 }

export function updateLearningMethodPropertyValues(course, updatedCourseData) {
    course.learningMethod.planning.startMoments = updatedCourseData.learningMethod.planning.startMoments 
    course.learningMethod.languageCode = updatedCourseData.learningMethod.languageCode
    course.learningMethod.languageMaterialCode = updatedCourseData.learningMethod.languageMaterialCode
    course.learningMethod.studyLoad.value = updatedCourseData.learningMethod.studyLoad.value
    course.learningMethod.studyLoad.unit = updatedCourseData.learningMethod.studyLoad.unit
    course.learningMethod.contactSessions = updatedCourseData.learningMethod.contactSessions
    course.learningMethod.format = updatedCourseData.learningMethod.format
}

export function updateInvoiceNewInvoiceItems(course, updatedCourseData) {
    updatedCourseData.learningMethod.planning.startMoments.forEach((startMoment, startMomentIndex) => {
        course.learningMethod.planning.startMoments[startMomentIndex].invoiceItems = startMoment.invoiceItems 
    }) 
    course.learningMethod.costs.details[0].invoiceItems = updatedCourseData.learningMethod.costs.details[0].invoiceItems 
}

export const navigationTabs = [
    {
        title: PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.Title,
        path: 'course-algemeen',
        isValid: false,
        isDisabled: false,
        hasErrors: false

    },
    {
        title: PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.Title,
        path: 'course-inhoud',
        isValid: false,
        isDisabled: false,
        hasErrors: false
    },
    {
        title: PROVIDER.Portfolio.CourseEdit.PlanningTab.Title,
        path: 'course-planning',
        isValid: false,
        isDisabled: false,
        hasErrors: false
    },
    {
        title: PROVIDER.Portfolio.CourseEdit.InvestmentTab.TabTitle,
        path: 'course-investering',
        isValid: false,
        isDisabled: false,
        hasErrors: false
    },
    {
        title: PROVIDER.Portfolio.CourseEdit.CategoriesTab.Title,
        path: 'course-categorieen',
        isValid: false,
        isDisabled: false,
        hasErrors: false
    },
    {
        title: PROVIDER.Portfolio.CourseEdit.DiplomaTab.Title,
        path: 'course-diploma',
        isValid: false,
        isDisabled: false,
        hasErrors: false
    }
]

export const getEnumaratorFromApi = async enumeratorType => {
try {
    return await getEnumList(
        process.env.VUE_APP_PROVIDER_API_URL,
        API_CALL_URL_PATHS.enumerations,
        enumeratorType,
        null,
        null,
        false
    )
} catch (error) {
    console.error('Something went wrong while retrieving enum list', error)
    return []
}}